.customTabDashboard {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1662ab !important;
  }
  .ant-tabs-tab {
    color: rgba(0, 0, 0, 0.4) !important;
  }
  .ant-tabs-ink-bar {
    background: #1662ab !important;
  }
}
.ant-tabs-nav {
  // margin: -15px !important;
}

.CascaderReport {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0px solid rgba(0, 0, 0, 0) !important;
  }
  .ant-select-selector {
    border: 0px solid rgba(0, 0, 0, 0) !important;
  }
}
