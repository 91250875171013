#interaction-history {
  background: #fff;
  .interaction-list {
    height: calc(100% - 32px);
    max-height: calc(100% - 32px);
    max-width: 100%;
    padding-bottom: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
  .interaction-history__main {
    .main__histories {
      list-style: none;
      padding-left: 0;
      display: flex;
      align-items: center;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
