#conditions {
    background: #ffffff;
    border: 1px solid #696666;
    border-radius: 12px;
    padding: 12px;
    .conditions__content {
        margin-bottom: 8px;
        max-height: 92px;
        overflow: auto;
        /* width */
        &::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background: #888;
        }

        .ant-tag {
            background: #d9d9d9;
            border-radius: 10px;
            margin: 0 8px 8px 0;
        }
    }
    .conditions__no-content {
        margin-bottom: 0;
    }
    .conditions__add {
        .ant-input {
            height: 30px;
            padding: 0 12px;
            border-radius: 8px;
        }
    }
}
