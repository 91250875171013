.modal-style {
  .text {
    padding: 0;
  }
  .text-center {
    text-align: center;
  }
  .title {
    color: #1890ff;
  }
  .ant-modal-header {
    padding: 16px 10px 0px;
  }
  .ant-modal-body {
    padding: 10px;
  }
}
