#data-divided-filters {
    padding: 25px 0;
    background: #eee;
    .ant-btn {
        border-radius: 5px;
        padding: 4px 28px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        background: linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
    }
    .button-active {
        color: #fff;
        background: linear-gradient(0deg, #ffa500, #ffa500), #ffa500;
    }
}
