.card {
  position: absolute !important;
  right: 0 !important;
  top: 40px;
  z-index: 9;
  width: 50% !important;
  height: 80px !important;
  border: 1px solid #277cbe !important;
  border-radius: 5px !important;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
  margin-top: -8px;
}

.ant-col.ant-form-item-label {
  margin-top: -8px;
}

.input {
  width: 20vw;
}

.ant-form-item-label > label::after {
  content: '' !important;
}
