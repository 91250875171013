@import 'variables';

a {
  transition: 0.2s;
}

#root {
  min-height: 100%;
  height: 100%;
}

.ant-table-body {
  overflow-x: hidden;
  overflow-y: auto !important;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.text-center {
  text-align: center !important;
}

.text-right {
  // text-align: right !important;
}

.txt-scale-medium {
  font-size: 0.73vw;
  padding: 0;
}

.txt-white {
  color: #fff;
}

.txt-blue {
  color: #61b6fb;
}

.txt-green {
  color: #52c41a;
}

.txt-red {
  color: #ff4d4f;
}

.txt-warning {
  color: #faad14;
}

.txt-gray {
  color: #9fa2a3;
}

.txt-error {
  color: #ff4d4f;
  font-size: 12px;
  padding-top: 5px;
}

.width-350 {
  width: 350px;
}

.width-150 {
  width: 150px;
}

.width-60 {
  width: 60px;
}

.label-small {
  width: 150px;
}

.txt-title {
  font-size: 20px;
  font-weight: 700;
}

.text-decoration-underline {
  text-decoration: underline;
}

.white-space-nowrap {
  white-space: normal;
}

.dpl-flex {
  display: flex;
}

.dpl-block {
  display: block;
}

.dpl-inline-block {
  display: inline-block;
}

.dpl-none {
  display: none !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-end {
  align-items: flex-end;
}

.pd-8 {
  padding: 0.5rem;
}

.pdl-5 {
  padding-left: 0.313rem;
}

.pdl-10 {
  padding-left: 0.625rem;
}

.pdl-15 {
  padding-left: 0.938rem;
}

.pdl-20 {
  padding-left: 1.25rem;
}

.pdl-25 {
  padding-left: 1.563rem;
}

.pdl-30 {
  padding-left: 1.875rem;
}

.pdr-5 {
  padding-right: 0.313rem;
}

.pdr-10 {
  padding-right: 0.625rem;
}

.pdr-15 {
  padding-right: 0.938rem;
}

.pdr-20 {
  padding-right: 1.25rem;
}

.pdr-25 {
  padding-right: 25px;
}

.pdr-30 {
  padding-right: 1.563rem;
}

.pdt-5 {
  padding-top: 0.313rem;
}

.pdt-10 {
  padding-top: 0.625rem;
}

.pdt-15 {
  padding-top: 0.938rem;
}

.pdt-20 {
  padding-top: 1.25rem;
}

.pdt-25 {
  padding-top: 25px;
}

.pdt-30 {
  padding-top: 1.563rem;
}

.pdbt-5 {
  padding-bottom: 0.313rem;
}

.pdbt-10 {
  padding-bottom: 0.625rem;
}

.pdbt-15 {
  padding-bottom: 0.938rem;
}

.pdbt-20 {
  padding-bottom: 1.25rem;
}

.pdbt-25 {
  padding-bottom: 25px;
}

.pdbt-30 {
  padding-bottom: 1.563rem;
}

.mgt-10 {
  margin-top: 0.875rem;
}

.mgt-15 {
  margin-top: 0.938rem;
}

.mgl-10 {
  margin-left: 10px;
}

.mgl-5 {
  margin-left: 0.313rem;
}

.mgr-5 {
  margin-right: 0.313rem;
}

.mgr-10 {
  margin-right: 0.625rem;
}

.mgr-16 {
  margin-right: 16px;
}

.mgbt-20 {
  margin-bottom: 20px;
}

.mgbt-40 {
  margin-bottom: 40px;
}

.mg-auto {
  margin: auto;
}

.logo {
  margin: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #fff;
  font-weight: 500;
}

.main-header {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-container {
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: flex-start;
  background: url('./images/background-login.jpg') center center;
  background-size: cover;

  .login-box {
    background: #ffffff;
    padding: 60px 60px;
    border-radius: 10px;
    padding-top: 4rem;
    width: 35%;
    min-height: 75%;
    margin-left: 6rem;
    border: 1px solid $primary-color;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    // -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    // -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

    .ant-input-affix-wrapper {
      border-radius: 8px;
    }
  }
}

.ant-btn {
  border-radius: 4px;
}

.btn-export {
  border: 1px solid $primary-color;
  border-radius: 5px;
  margin: 0;
  color: $primary-color;
}

.btn-filter {
  border: 1px solid $primary-color;
  border-radius: 5px;
  color: $primary-color;
}

.list-menu-profile {
  background: #fff;
  padding: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

  .ant-divider {
    margin: 10px 0;
  }

  .list-menu-profile-item {
    cursor: pointer;
  }
}

.box-search {
  display: flex;
  // align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  .box-search-row {
    display: flex;
    align-items: center;
  }
  .box-search-item {
    padding-right: 10px;
    max-width: 250px;
    width: 100%;
    &.medium {
      max-width: 350px;
    }
    &.large {
      max-width: 500px;
    }

    .ant-input-number {
      width: 100%;
      .ant-input-number-handler-wrap {
        display: none;
      }
    }

    .ant-input-number-group-wrapper {
      width: 100%;
    }

    .ant-select {
      width: 100%;
    }
    .ant-picker {
      width: 100%;
    }
    &.picker-range {
      max-width: 300px;
    }
    .ant-picker-range {
      min-width: 250px;
    }
  }
}

.ant-form-item-control-input {
  .ant-input-number {
    width: 100%;
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
}

.list-data-cell {
  div {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.scroll-box {
  overflow-y: auto;
}

.custom-empty {
  .ant-empty {
    margin: 10px 0;
  }
}

.ant-table-thead > tr > th {
  padding: 10px 5px;
  background: $primary-color;
  color: #fff;
  font-size: 0.73vw;
  // text-align: center;
}

.ant-table-tbody {
  > tr > td {
    padding: 1px 8px;
    font-size: 0.73vw;
    color: #000000;
    // text-align: center;
  }
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.box-register-package {
  display: inline-flex;
  padding: 10px 10px;
  margin-bottom: 10px;
  margin-left: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // -webkit-box-shadow: 0px 5px 10px 0px rgba(187, 187, 187, 0.5);
  // -moz-box-shadow: 0px 5px 10px 0px rgba(187, 187, 187, 0.5);

  .box-register-package-item {
    width: 200px;
    margin-right: 10px;
    .ant-input-number {
      width: 100%;
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
    .ant-select-selector {
      border: 1px solid $black-color-border !important;
      border-radius: 0px !important;
    }
    .ant-select {
      width: 100%;
    }
    //.ant-tree-select {
    //  width: 100%;
    //}
  }
}

.custom-pagination {
  margin: 16px 16px;
  text-align: right;
}

.ant-btn {
  &.active {
    color: $primary-color;
    border-color: $primary-color;
  }
  &.default {
    color: $black-color-border;
    border-color: $black-color-border;
  }
}

.ant-divider-vertical {
  &.custom {
    height: 28px;
    border-color: #fff;
  }
}

.ant-modal-footer {
  border-top: none;
}

.box-register-package-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  .box-register-package-new {
    width: 50%;
    height: 200px;
    background-image: linear-gradient(#40a9ff, #ececec);
    border-radius: 20px;
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    > div {
      width: 100%;
    }
    .btn-register-container {
      text-align: center;
      margin-top: 16px;
      .btn-register {
        padding-right: 40px;
        padding-left: 40px;
      }
    }
    .box-register-package-new-title {
      text-transform: uppercase;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      margin-bottom: 20px;
    }
    .box-register-package-item {
      width: 100%;
      padding: 0 16px 20px 16px;
      position: relative;
      .txt-error {
        position: absolute;
        bottom: 0;
      }

      .ant-input-number {
        width: 100%;
        .ant-input-number-handler-wrap {
          display: none;
        }
      }
      .ant-select {
        width: 100%;
      }
    }
  }
}

.cell-otp-container {
  .ant-input-number,
  .ant-input-number-group-wrapper {
    width: 100%;
  }
}

.btn-primary-ghost {
  border-color: $primary-color;
  color: $primary-color;
  background: transparent;
  &:hover {
    background: $primary-color;
    color: #fff;
  }
}

.ant-btn-primary {
  background-color: $primary-color;
}

.box-showdow-bottom {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.set-height-0px {
  height: 0px;
}
.ant-select-selector {
  border: 1px solid $black-color-border !important;
  border-radius: 0px !important;
}

.ant-select-selection-placeholder {
  color: $black-color-border !important;
}

.ant-input-number-affix-wrapper {
  padding: 0px 12px 0px 12px !important;
}

.filters-box {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.label-input-base {
  display: flex;
  gap: 10px;
  align-items: center;
}

.column-input-base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
