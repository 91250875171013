#revenue-reports-total {
  .ant-badge {
    sup {
      color: #000;
    }
  }
  .table-reports-total {
    .ant-table-thead > tr > th {
      padding: 0 !important;
      background: #fff;
      color: #fff;
    }

    .ant-table-tbody {
      > tr > td {
        padding: 0.7vh 8px;
        font-size: 0.73vw;
        text-align: center;
      }
      tr:hover {
        background: rgba(217, 217, 217, 0.4);
      }
    }
  }
}

.bg-gray {
  background: rgba(217, 217, 217, 0.2) !important;
}
