#noty-missed-call {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background: #f5f5f5;
  padding: 20px;
  padding-top: 8px;
  justify-content: center;
  border: 1px solid #277cbe;
  border-radius: 6px;
  z-index: 9;
  .header {
    display: flex;
    padding-right: 16px;
  }
  .mrbt-10 {
    margin-bottom: 10px;
  }
  .text-bold {
    font-weight: bold;
  }
  .icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .text {
    padding: 0;
    margin: 0;
  }
  .color-blue {
    color: #00508e;
  }
  .text-underline {
    position: absolute;
    bottom: 6px;
    right: 10px;
    color: #00508e;
    text-decoration: underline;
    text-decoration-style: solid;
  }
  .item-missed-call {
    display: flex;
    border-top: 0.5px solid #a8a8a8;
    padding-top: 4px;
    padding-bottom: 4px;
    align-items: center;
    .icon-missed {
      width: 24px;
      height: 24px;
      margin-right: 18px;
    }
  }
}
