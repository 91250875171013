.ThreeTypeChart {
  // width: 600px;
  // min-width: 600px;
  // width: calc((100vh - 110px) / 2 * 6 / 4);
  // height: calc((100vh - 110px) / 2);
}

.TwoTypeChart {
  margin-right: 20px;
  min-width: 400px;
}

.saleOverview {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  background: #fff;
  // margin-top: 10px;
  border-radius: 10px;
  width: 100%;
}

.saleOverviewLabel {
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.saleOverviewValue {
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.tableChartName {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  // width: 600px;
  // min-width: 600px;
  // height: 400px;
}

.revenueOverview {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  background: #fff;
  // margin-top: 10px;
  border-radius: 10px;
  width: 100%;
}

.revenuewOverviewLabel {
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.revenueOverviewValue {
  font-weight: bold;
  font-size: 20px;
}

.freezeTag {
  width: calc(100vw - 4px);
}

.antTableCustom {
  .ant-table-thead {
    height: 40px !important;
  }
  .ant-table-cell {
    padding-bottom: 3px !important;
    padding-top: 3px !important;
  }
}
