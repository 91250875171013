.collapsePanel {
  .anticon {
    color: #fff;
  }
  .ant-collapse-header-text {
    width: 100%;
    color: #fff;
    white-space: nowrap;
  }

  .ant-collapse-header {
    /* background-color: red; */
  }

  .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
  }

  .ant-collapse-content-box {
    margin: -16px;
  }

  .ant-table-cell {
    height: 30px;
  }
}

.dataCaringView {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.dataCaringContent {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
}

.LoadingView {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading {
  font-size: 40px;
  color: #1890ff;
  margin-top: 100px;
}

.appointment {
  background: #ea232a;
}

.newData {
  background: #eaba3e;
}

.careAgain {
  background: #1963ae;
}
