#data-divided {
  background-color: #ececec;
  .block {
    background-color: #fff;
    height: 350;
    &__header {
      text-align: center;
      padding: 8px 0;
      background-color: #ffa500;
      h2 {
        color: #fff;
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
    }
    &__main {
      padding: 3px 10px;
    }
  }
}

#col12 {
  background: white;
  position: relative;
  padding-right: 0 !important;
  height: calc(100vh - 88px);
}

#col12::before {
  content: '';
  width: 4px;
  height: 100%;
  background: #eee;
  position: absolute;
  transform: translateX(-6px);
}
