@import '../../../../resources/variables.scss';

.data-divided-filters {
  margin: 0.8vw;
  margin-top: 0.4vw;
  margin-bottom: 0.4vw;
  padding: 10px 10px 10px 10px;
}

.data-filters {
  width: 100%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.btn-menu {
  border-color: $primary-color;
  color: $primary-color;
}
.modal-move-data {
  display: grid;
  place-items: center;
}
.text {
  margin: 0px;
  padding: 0px;
}
.text-bold {
  font-weight: bold;
  color: #2f78ba;
}
.text-small {
  font-size: 12px;
}
