.layout-common {
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: #1890ff;
      .ant-menu-submenu-arrow {
        &::after,
        &::before {
          background: #1890ff !important;
        }
      }
      &:hover {
        .ant-menu-submenu-arrow {
          &::after,
          &::before {
            background: #fff !important;
          }
        }
      }
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;

  .version {
    color: #fff;
  }
}

.site-layout .site-layout-background {
  background: #fff;
  height: 100%;
}

.ant-layout-content {
  .site-layout-background {
    position: relative;
    height: 100%;
  }

  .ant-pagination {
    padding-right: 16px;
  }
}

.fixed-header {
  .ant-layout-header {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
  }

  .ant-layout-content {
    padding-top: 84px;
  }
}

.fixed-sidebar {
  .ant-layout-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    // z-index: 1000;
  }

  .site-layout {
    margin-left: 248px;
  }

  .ant-layout-sider-collapsed + .site-layout {
    margin-left: 80px;

    &.fixed-header {
      .ant-layout-header {
        left: 80px;
      }
    }
  }

  section.ant-layout.site-layout.fixed-header {
    max-height: 100vh;
    overflow: hidden;
  }
}

.calls {
  color: white;
  width: 33px;
  height: 33px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
}

.numberOfDataCaring {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}
