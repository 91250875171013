p {
  margin: 0;
  padding: 0;
}

.custom-table {
  /* max-height: 100px; */
  .ant-pro-card .ant-pro-card-body {
    padding-inline: 0px;
  }
  .ant-typography {
    color: #2c67da;
  }
  .ant-table-cell {
    font-size: 12px;
    white-space: nowrap;
  }

  .anticon {
    color: #000;
  }
}

.ant-table
  .ant-table-container
  table
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  padding-bottom: 10px;
  padding-top: 10px;
}
