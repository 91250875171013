#box-call-to-customer {
  position: absolute;
  padding-left: 12px;
  padding-right: 12px;
  right: 8px;
  top: 52px;
  z-index: 10;
  width: 49%;
  height: 60px;
  border: 1px solid #277cbe;
  background: #d5eafb;
  flex-direction: row;
  display: flex;
  align-items: center;
  .btn-turn-off {
    margin-left: 12px;
    background-color: #EE3E3E;
    color: #fff;
  }
  .txt-normal {
    margin-bottom: 0;
  }
  .blue {
    color: #277cbe;
  }
  .bold-500 {
    font-weight: 500;
  }
}
