@import '../../../resources/variables.scss';

#import-data {
  .import-data-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;

    .btn-download-file-example {
      display: flex;
      border: 1px solid #1677ff;
      border-radius: 5px;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .btn-upload-file {
    color: #ffffff;
    background: $primary-color;
    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 5px;
  }

  .btn-import-container {
    display: inline-block;
    border-radius: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 0px 5px 10px 0px rgba(187, 187, 187, 0.5);
    -webkit-box-shadow: 0px 5px 10px 0px rgba(187, 187, 187, 0.5);
    -moz-box-shadow: 0px 5px 10px 0px rgba(187, 187, 187, 0.5);
    .btn-import-excel {
      cursor: pointer;
    }
  }

  .file-container {
    display: flex;
    align-items: center;
    padding: 12px;
    .file-info {
      padding-right: 30px;
      position: relative;
      font-size: 16px;
      font-weight: 400;
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-color: #000;
    }

    .btn-remove-excel-file {
      position: absolute;
      top: 0px;
      right: 17px;
      font-size: 14px;
      cursor: pointer;
    }
    .ant-select-selector {
      border: 1px solid $black-color-border !important;
      border-radius: 0px !important;
    }
  }
}
