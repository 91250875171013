#cell-center {
  .ant-table-body {
    &::-webkit-scrollbar {
      height: 4px;
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .filter-container {
    display: flex;
    justify-content: 'space-between';
    padding: 10px;
  }
}
