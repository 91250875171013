.edit-level-sale-modal {
    // .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-affix-wrapper {
        padding: 0 16px;
        height: 48px;
        border-radius: 8px;
        display: flex;
        align-items: center;
    }
    .ant-input-affix-wrapper-textarea-with-clear-btn {
        height: unset;
        textarea {
            height: 100%;
        }
    }
    .ant-select-dropdown {
        padding: 0;
        border-radius: 8px;
        .ant-select-item {
            height: 56px;
            padding: 16px;
            font-size: 16px;
            line-height: 24px;
            color: #1a1a1a;
            font-weight: 400;
        }
        .ant-select-item-option-selected {
            background: #e3eef8;
        }
    }
    .footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-modal-content {
        border-radius: 12px;
        .ant-modal-header {
            border-radius: 12px 12px 0 0;
            border-bottom: 0;
            .ant-modal-title {
                text-align: center;
                color: #277cbe;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}
