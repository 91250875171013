.treeSelect.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.ant-select .ant-select-selector {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.data-manager-filters {
  margin: 0.8vw;
  margin-top: 0.4vw;
  margin-bottom: 0.4vw;
  padding: 1vw;
}

.hide-box {
  height: 0px;
}
