.ant-modal {
    .ant-modal-content {
        border-radius: 12px;
        .ant-modal-body {
            padding: 24px;
        }
    }
    .ant-modal-header {
        border-radius: 12px;
        border-bottom: 0;
        .ant-modal-title {
            .title-cs {
                color: #277cbe;
            }
            text-align: center;
        }
    }
    .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
