#data-divided-info {
  position: relative;
  .ant-page-header {
    background-color: #277cbe;
    padding: 0px 8px;
    .ant-page-header-heading {
      .ant-page-header-heading-left {
        width: 100%;
        .ant-page-header-back {
          .anticon {
            color: #fff;
          }
        }
        .ant-page-header-heading-title {
          color: #fff;
          display: flex;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
}

.btn-calling {
  border: 1px solid #1677ff !important;
  border-radius: 5px;
  margin: 0;
  color: #1677ff !important;
  position: relative;
}

.btn-calling-status {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  top: 3px;
  right: 3px;
  animation: wave 2s infinite ease-in-out;
}
.status-red {
  background-color: rgb(255, 0, 0);
}

.status-yellow {
  background-color: #ffa500;
}

.status-green {
  background-color: rgb(35, 222, 35);
}

@keyframes wave {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}

// .ant-popover.wrapper-notify {
//   width: 300px;
//   .ant-popover-inner {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
