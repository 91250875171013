.subscriber-profile {
  margin-top: 12;
  .basic-info {
    display: flex;
    justify-content: space-between;
  }
  .btn-search-tb {
    border: 1px solid #67be20;
    border-radius: 5px;
    inline-size: 100px;
    overflow-wrap: break-word;
    inline-size: contain;
    text-align: center;
    color: #67be20;
  }
}

.content-basic-info {
  margin-bottom: 16px;
  padding-left: 2px;
  padding-right: 2px;
  .box-title {
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    .text-title {
      color: #277cbe;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .box-content {
    padding-left: 24px;
    padding-right: 24px;
    .item-info {
      margin-top: 10px;
      .item-info-text-bold {
        font-size: 16px;
        color: #000000;
        font-weight: bold;
      }
    }
  }
  .table-title {
    background: #f2f2f2;
    color: #32325d;
    font-weight: 700;
    font-size: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 12px;
  }
  .table-item {
    padding-left: 12px;
  }
  .ant-table-thead > tr > th {
    padding: 10px 16px;
    background: #f2f2f2;
    color: #000000;
    font-size: 0.76vw;
    font-weight: bold;
  }
  .ant-table-tbody {
    > tr > td {
      padding: 0.7vh 8px;
      font-size: 0.74vw;
    }
  }
}

.text-title-css {
  color: #277cbe;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
