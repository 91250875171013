.topup-filters {
  margin: 0.8vw;
  margin-top: 0.4vw;
  margin-bottom: 0.4vw;
  padding: 1vw;
}

.hide-box {
  height: 0px;
}
